import { queryFetch } from "apiConnectors/queryFetch";
import { BankAccountActivationDTO } from "./models";

const getTransactions = (data: { bankAccountId: number }) =>
  queryFetch<void>({
    method: "POST",
    url: "/bank-transactions/get-transactions",
    data,
  });

const refreshBankAccount = (data: { referenceId: string; bankAccountId: string }) =>
  queryFetch<BankAccountActivationDTO>({
    method: "POST",
    url: `/bank-accounts/connect-to-users-bank-accounts/refresh-account/` + data.bankAccountId,
    data: {
      referenceId: data.referenceId,
    },
  });

export const bankAccountsApi = {
  getTransactions,
  refreshBankAccount,
};
