import { Button, Header as CommonHeader, Toolbar } from "components/common";
import listEmptyIcon from "assets/images/el2.png";
import { Cell, CommonError, Label, List, Pagination, Row, Spinner } from "components/utils";
import { Link, useHistory } from "react-router-dom";
import plusIcon from "assets/images/20c.svg";
import arrowIcon from "assets/images/526p.svg";
import { useBankAccounts } from "api/bank-accounts/hooks";
import { NoContent } from "components/utils/noContent";
import styles from "./BankAccountList.module.css";
import cx from "classnames";
import { differenceInHours } from "date-fns";
import { CURRENCY } from "CONSTANTS";
import { useQuery, useSelector, useToggle } from "hooks";
import { bankAccounts } from "components/common/moduleNavigation/moduleConfig/finances/routes/bankAccounts";
import { UploadTransactionFiles } from "./uploadTransactionFiles/UploadTransactionFiles";
import { UploadFilesModal } from "components/common/uploadFiles/UploadFiles";
import {
  postCsvFromAllegro,
  postCsvFromEbay,
  postCsvFromKaufland,
  postCsvFromPaypal,
  postTxtFromAmazon,
} from "api/trading-documents/calls";
import { Button as MDSButton } from "components/miloDesignSystem/atoms/button";
import { MdiSync } from "components/miloDesignSystem/atoms/icons/MdiSync";
import { bankAccountsActions } from "api/bank-accounts/actions";
import { MdiVerticalAlignBottom } from "../../../components/miloDesignSystem/atoms/icons/MdiVerticalAlignBottom";
import { MdiQualityAlert } from "components/miloDesignSystem/atoms/icons/MdiQualityAlert";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { BankAccount } from "api/bank-accounts/models";

export const BankAccountsList = () => {
  const { search } = useQuery();
  const { data, pagination, isLoading, error } = useBankAccounts(search);
  const history = useHistory();

  if (error) {
    return (
      <div className="h-100">
        <Header />
        <CommonError status={error._httpStatus_} />
      </div>
    );
  }

  if (!data || isLoading) {
    return (
      <div className="h-100">
        <Header />
        <div className="d-flex align-items-center justify-content-center flex-1 h-100">
          <Spinner color="blue" size="big" text="trwa wczytywanie" position="absolute" />
        </div>
      </div>
    );
  }

  if (data.length === 0) {
    return (
      <div>
        <Header />
        <NoContent header="Lista nie zawiera elementów" img={listEmptyIcon} />;
      </div>
    );
  }

  return (
    <div className="position-relative">
      <Header />

      <List variant="compact">
        {data.map(bankAccount => {
          const isNew = isBankAccountNew(bankAccount.created);
          return (
            <Row key={bankAccount.id} className={cx(styles.bankAccount, { "bg-white": isNew })}>
              <Cell className={cx(styles.imageCell, "mr-3")}>
                <img src={bankAccount.logo} alt="logo" />
              </Cell>
              <Cell className={styles.nameCell}>
                <div>
                  <div className={styles.product}>
                    <div className={cx(styles.iban, "mb-1")}>
                      <div className="mr-2">{bankAccount.iban}</div>{" "}
                      {bankAccount.isActive && (
                        <Label color="primary" className="mr-2">
                          Aktywne
                        </Label>
                      )}{" "}
                      {isNew && <Label color="blue">Nowe</Label>}
                    </div>
                    <div
                      className={cx(styles.details, {
                        "text-color-black": isNew,
                      })}
                    >
                      <div className="mb-1">{bankAccount.name}</div>
                      <div>{bankAccount.currency}</div>
                    </div>
                  </div>
                </div>
              </Cell>
              {Boolean(bankAccount.errorMessage?.length) && (
                <Cell className={cx("d-flex align-items-center gap-2 px-4", styles.errorMessage)}>
                  <MdiQualityAlert color="danger400" />
                  <Typography color="danger400" fontFamily="cabin" fontSize="14" fontWeight="600">
                    {bankAccount.errorMessage}
                  </Typography>
                </Cell>
              )}
              <Cell>
                <div className="d-flex align-items-center gap-3">
                  {bankAccount.isActive ? (
                    <DownloadTransactionsButton bankAccount={bankAccount} />
                  ) : (
                    <RefreshBankAccountButton bankAccount={bankAccount} />
                  )}
                  <Button
                    size="small"
                    kind="secondary-stroke"
                    onClick={() =>
                      history.push(
                        "/bank-accounts/transactions/list/in?bankAccount=" + bankAccount.id,
                      )
                    }
                  >
                    lista transakcji <img src={arrowIcon} alt="arrow" className="ml-1" />
                  </Button>
                </div>
              </Cell>
            </Row>
          );
        })}
        <Pagination pagination={pagination} />
      </List>
    </div>
  );
};

const DownloadTransactionsButton = ({ bankAccount }: { bankAccount: BankAccount }) => {
  const transactionsMutation = bankAccountsActions.useGetTransactions();

  return (
    <MDSButton
      className="text-uppercase"
      isLoading={transactionsMutation.isLoading}
      onClick={() =>
        transactionsMutation.mutate({
          bankAccountId: bankAccount.id,
        })
      }
      size="small"
      startIcon={MdiVerticalAlignBottom}
      variant="gray"
    >
      Pobierz transakcje
    </MDSButton>
  );
};

const RefreshBankAccountButton = ({ bankAccount }: { bankAccount: BankAccount }) => {
  const refreshBankAccountMutation = bankAccountsActions.useBuildBankAccountLink();

  return (
    <MDSButton
      className="text-uppercase"
      isLoading={refreshBankAccountMutation.isLoading}
      onClick={() => {
        refreshBankAccountMutation.mutate({
          id: bankAccount.code,
          logo: bankAccount.logo,
          name: bankAccount.name,
          redirectUri: `${window.location.origin}/bank-accounts/create/step-three?bankAccountId=${bankAccount.id}`,
        });
      }}
      size="small"
      startIcon={MdiSync}
      variant="gray"
    >
      Odnów zgody
    </MDSButton>
  );
};

const Header = () => {
  const banks = useSelector(state => state.partials.banks);
  const uploadTransactionFilesModal = useToggle();
  const uploadCsvAllegroModal = useToggle();
  const uploadCsvEbayModal = useToggle();
  const uploadCsvPaypalModal = useToggle();
  const uploadCsvKauflandModal = useToggle();
  const uploadTxtAmazonModal = useToggle();

  const currencyList = CURRENCY.map(({ id, name }) => ({ label: name, value: id }));
  const bankList = banks.map(({ id, name }) => ({ label: name, value: String(id) }));
  return (
    <>
      <div className={styles.headerWrapper}>
        <CommonHeader
          title={({ styles: s }) => (
            <div className="d-flex align-items-center">
              <h1 className={s.title}>Konta bankowe</h1>
              <div className="d-flex align-items-baseline ml-3">
                <Button
                  kind="secondary-stroke"
                  size="small"
                  as={Link}
                  to="/bank-accounts/create"
                  className="mr-2"
                >
                  <img src={plusIcon} alt="" className="mr-1" />
                  Dodaj konto bankowe
                </Button>
                <Button
                  kind="secondary-stroke"
                  size="small"
                  className="mr-2"
                  onClick={uploadTransactionFilesModal.open}
                >
                  <img src={plusIcon} alt="" className="mr-1" />
                  Importuj
                </Button>
              </div>
            </div>
          )}
          routeRoot={`finances/${bankAccounts.url}`}
        />
        <Toolbar
          searchInput
          filters={[
            {
              name: "ordering",
              label: "sortuj",
              type: "string",
              options: [
                { label: "Od najstarszych", value: "id" },
                { label: "Od najnowszych", value: "" },
              ],
              default: "",
            },
            {
              name: "bank",
              label: "bank",
              type: "string",
              options: [{ label: "Wszystkie", value: "" }, ...bankList],
              default: "",
            },
            {
              name: "currency",
              label: "waluta",
              keepTextFormatting: true,
              type: "string",
              options: [{ label: "Wszystkie", value: "" }, ...currencyList],
              default: "",
            },
          ]}
        />
      </div>
      {uploadTransactionFilesModal.isOpen && (
        <UploadTransactionFiles
          close={uploadTransactionFilesModal.close}
          uploadCsvAllegroModal={uploadCsvAllegroModal}
          uploadCsvEbayModal={uploadCsvEbayModal}
          uploadCsvPaypalModal={uploadCsvPaypalModal}
          uploadCsvKauflandModal={uploadCsvKauflandModal}
          uploadTxtAmazonModal={uploadTxtAmazonModal}
        />
      )}
      {uploadCsvAllegroModal.isOpen && (
        <UploadFilesModal
          accepts={[".csv"]}
          fetcher={postCsvFromAllegro}
          modal={uploadCsvAllegroModal}
          title="Dodaj plik CSV - Allegro"
        />
      )}
      {uploadCsvEbayModal.isOpen && (
        <UploadFilesModal
          accepts={[".csv"]}
          fetcher={postCsvFromEbay}
          modal={uploadCsvEbayModal}
          title="Dodaj plik CSV - Ebay"
        />
      )}
      {uploadCsvPaypalModal.isOpen && (
        <UploadFilesModal
          accepts={[".csv"]}
          fetcher={postCsvFromPaypal}
          modal={uploadCsvPaypalModal}
          title="Dodaj plik CSV - Paypal"
        />
      )}
      {uploadCsvKauflandModal.isOpen && (
        <UploadFilesModal
          accepts={[".csv"]}
          fetcher={postCsvFromKaufland}
          modal={uploadCsvKauflandModal}
          title="Dodaj plik CSV - Kaufland"
        />
      )}
      {uploadTxtAmazonModal.isOpen && (
        <UploadFilesModal
          accepts={[".txt"]}
          fetcher={postTxtFromAmazon}
          modal={uploadTxtAmazonModal}
          title="Dodaj plik TXT - Amazon"
        />
      )}
    </>
  );
};

function isBankAccountNew(created: string) {
  return differenceInHours(new Date(), new Date(created)) < 6;
}
